import ReactGA from "react-ga4";

interface TrackingData {
  [key: string]: any;
}

const useTrackEvent = () => {
  const trackEvent = (
    eventName: string,
    category?: string,
    label?: string,
    data?: TrackingData
  ) => {
    ReactGA.event({
      category: category || "form",
      action: eventName,
      label: label || undefined,
      ...data
    });
    if ((window as any).fbq && typeof (window as any).fbq === 'function') {
      (window as any).fbq('trackCustom', eventName, data);
    }
    if ((window as any).ttq && typeof (window as any).ttq.track === 'function') {
      (window as any).ttq.track(eventName, data);
    }
  };
  console.log('useTrackEvent');
  return trackEvent;
};

export default useTrackEvent;

